import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  public locale: string = 'en-GB';
  constructor(private http: HttpClient) { }

  // Get the IP address
  async getIP(): Promise<string> {
    try {
      const response = await firstValueFrom(
        this.http.get<any>(`${environment.ipUrl}=${new Date().getTime()}`)
      );
      return response.ip;
    } catch (error) {
      console.error('Error fetching IP:', error);
      throw error;
    }
  }

  // Get location information using IP address
  async getGeoLocation(ip: string): Promise<any> {
    const locationUrl = environment.locationUrl;
    try {
      return await firstValueFrom(
        this.http.get<any>(`${locationUrl}/${ip}?key=${environment.geoLocationKey}`)
      );
    } catch (error) {
      console.error('Error fetching geolocation:', error);
      throw error;
    }
  }

  // Fetch location and determine if it's US or not
  async fetchLocation(): Promise<string> {
    const ip = await this.getIP();
    const locationData = await this.getGeoLocation(ip);

    if (locationData.countryCode === 'US') {
      this.locale = 'en-US';
    } else {
      this.locale = 'en-GB';
    }
    return locationData.countryCode === 'US' ? 'US' : 'GB';
  }
}
